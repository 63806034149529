import React from 'react'
import { Link } from "gatsby"

import './nav.css'

const Nav = () => {
    return (
        <div className="container mx-auto my-6">
            <nav className="flex flex-col flex-wrap font-title items-center justify-center lg:justify-center md:-m-1 md:flex md:flex-row md:justify-around md:text-center xl:justify-center">
                <Link to="/" activeClassName="active" className="uppercase">Home</Link>
                <Link to="/our-story" activeClassName="active" className="uppercase">Our Story</Link>
                <Link to="/menu" activeClassName="active" className="uppercase">Menu</Link>
                <Link to="/gallery" activeClassName="active" className="uppercase">Gallery</Link>
                <Link to="/contact-us" activeClassName="active" className="uppercase">Contact Us</Link>
            </nav>
        </div>
    )
}

export default Nav