import React from 'react'

import footerImg from '../images/footer-img-2.png'
import footerImgLogo from '../images/kabuhayan-cafe-logo.png'
import facebook from '../images/facebook.png'
import twitter from '../images/twitter.png'
import instagram from '../images/instagram.png'

const Footer = () => (
    <footer className="mx-auto mt-32">
        <div className="footer-top">
            <div className="map relative">
                <div className="map-info absolute text-center pb-10 md:pb-0 lg:pb-0 xl:pb-0">
                    <img src={footerImgLogo} alt="" className="mx-auto pt-10 mb-10 w-1/2" />
                    <p className="map-info__address mb-4 px-24">Street 20, Community 333 Satwa, Dubai, UAE</p>
                    <p className="map-info__phone mb-4">04 273 8356</p>
                    <p className="map-info__email mb-4">hello@kabuhayancafe.com</p>
                    <div className="map-info__socia-media mt-10">
                        <ul className="flex flex-wrap justify-center">
                            <li><a href="https://www.facebook.com/kabuhayancafe" target="_blank"><img src={facebook} alt="" className="w-8 mx-2" /></a></li>
                            {/* <li><a href="#" target="_blank"><img src={twitter} alt="" className="w-8 mx-2"/></a></li>
<li><a href="#" target="_blank"><img src={instagram} alt="" className="w-8 mx-2"/></a></li> */}
                        </ul>
                    </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d902.2799203744776!2d55.27549462857739!3d25.232893626371514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4363e526826b%3A0x4cdccb238992e960!2sKabuhayan%20Cafe!5e0!3m2!1sen!2sae!4v1568252280754!5m2!1sen!2sae" width="100%" height="450" frameBorder="0" allowFullScreen=""></iframe>
            </div>
        </div>
        <div className="footer-bottom py-10">
            <div className="container mx-auto flex flex-wrap justify-between">
                <div className="w-full mb-10 md:w-6/12 lg:w-3/12 xl:w-3/12 xl:mb-0 ">
                    <img src={footerImg} alt="" />
                </div>
                <div className="w-full mb-10 px-5 md:w-6/12 lg:w-4/12 xl:w-4/12 xl:mb-0  xl:px-12 text-center schedule">
                    <h3 className="schedule__title font-title mb-5 pb-2">Opening Hours:</h3>
                    <ul>
                        <li>Serving you daily from 5 am - 12 midnight</li>

                    </ul>
                </div>
                <div className="w-full px-5 text-center lg:text-left lg:w-5/12 lg:px-0  xl:w-5/12 xl:px-0 ">
                    <h3 className="schedule__title font-title mb-2 pb-2">Newsletter:</h3>
                    <p className="schedule__desc my-5">Subscribe to get regular updates for new stuff and events.</p>
                    <div className="newsletter-form flex">
                        <input className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none leading-normal mr-2" type="email" placeholder="jane@example.com" />
                        <button className="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">Send</button>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer;