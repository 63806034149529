//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"

import Nav from './nav'
import Logo from "../components/logo"
import './header.css'

import phone from "../images/phone.svg"

const Header = ({ siteTitle }) => (
  <header className="mb-6">
    <div className="header-info">
      <div className="container justify-between md:flex flex justify-center mx-auto py-3 sm:flex-wrap text-center xl:flex xl:text-0 flex-col sm:flex-row ">
        <p className="font-sans w-full md:w-7/12 text-center md:text-left">
          Serving you daily from 5 am - 12 midnight
        </p>
        <p className="flex items-center flex-wrap w-full md:w-3/12 text-center md:text-left justify-center md:justify-end">
          Call us: <img src={phone} className="mx-3" />
          <a href="tel:042738356" title="Tap to Call">04 273 8356</a>
        </p>
      </div>
    </div>
    <div className="logo mx-auto">
      <Logo />
    </div>
    <Nav />
  </header>
)

export default Header
